let elm = document.getElementById('add-remove-wishlist-link');

elm.classList.add('active');

 /* 
if(elm.className === 'active'){
    elm.className = 'inactive';
} else {
    elm.className = 'active';
}

$(document).ready(function(){
    $("#add-remove-wishlist-link").click(function(){
        $(".ait-tooltip").addClass("added-w");
    });
});
*/